
/*********************************************************************

		PROJECT Config

*********************************************************************/


projectConfig = {

/*----- Some Default Values -----------------------------------------*/

	defaultEasing: 'easeOutExpo',
	intervalId: -1,


/*----- Waypoint Navigation -----------------------------------------*/


	navigation: {
		config: {
			element: $('#page-header')[0],
			wrapper: '<div id="page-header-placeholder" />',
			stuckClass: 'sticky',
			offset: -111
		}
	},




/*----- Content Animation Options -----------------------------------------*/


	content: {
		scrollOptions: {
			axis: 'y',
			duration: 800,
			offset: -110,
			easing: 'easeInOutExpo',
			hash: true,
			lazy: true,
			stop: true,
			onAfter: function() {
				$('#device').css('height', '0px');
			}
		}
	}
}



/*----- Simple Mobile Detection -----------------------------------------*/

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    iPhone: function() {
	    return navigator.userAgent.match(/iPhone|iPod/i);
    },
    iPad: function() {
	    return navigator.userAgent.match(/iPad/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    },
    phone: function() {
	    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iPhone() || isMobile.Opera() || isMobile.Windows());
    }
};



/*********************************************************************

		Global Settings & Functions

*********************************************************************/


/*----- Set Default jQuery Easing -----------------------------------------*/


jQuery.easing.def = projectConfig.defaultEasing;




/*----- Scrollspy/Waypoints configuration -----------------------------------------*/


function addWayPoints() {


	//Add Sticky Navigation
	var sticky = new Waypoint.Sticky(projectConfig.navigation.config);

	$('section').each(function() {

		new Waypoint({
			element: this,
			offset: '100%',
			handler: function(direction) {
				var $links = $('a[href$="#' + this.element.id + '"]');
			    $links.toggleClass('active', direction === 'down');
			}
		});

		new Waypoint({
			element: this,
			offset: function() {
				return -$(this.element).height()+110;
			},
			handler: function(direction) {
				var $links = $('a[href$="#' + this.element.id + '"]');
			    $links.toggleClass('active', direction === 'up');
			}
		});
	});
}


/*----- ScrollTo Function -----------------------------------------*/


//Animate Anchors
function addScrollTo() {
	$('a.scroll').bind('click', function(e) {
		var href = $(this).attr('href');
		var hrefString = href.split('#')[0];

		var url = document.URL.split('/');
		urlString = url[url.length-1].split('#')[0];

		if(urlString == hrefString) {

			if(href.indexOf('#') > 0) {
				href = '#'+href.split('#')[1];
			}

			$.scrollTo(href, projectConfig.content.scrollOptions);


		}

		e.preventDefault();
	});
}



/*----- Resize Function -----------------------------------------*/


function triggerResize() {

	clearInterval(projectConfig.intervalId);
	Waypoint.refreshAll();
	$('#page-header-placeholder').height($('#page-header').height());
}


/*----- Mobile Navigation Interaction -----------------------------------------*/


//Add Navigation Interaction for Mobile
function addMobileNavigation() {
	$('#menu-button').click(function(e) {
		if(!$('#page-header').hasClass('open')) {
			$('#page-header').addClass('open');
		} else {
			$('#page-header').removeClass('open');
		}

		e.preventDefault();
	});

	$('#page-header ul a.scroll').click(function(e) {
		if($('#menu-button').css('display') == 'block') {
			$('#page-header').removeClass('open');
		}
	});
}









/*********************************************************************

		DOCUMENT SCRIPT INIT

*********************************************************************/


$(document).ready(function() {


	$("input.search-field").each(function () {
	    // store default value
	    var v = this.value;

	    $(this).blur(function ()
	    {
	        // if input is empty, reset value to default
	        if (this.value.length == 0) this.value = v;
	    }).focus(function ()
	    {
	        // when input is focused, clear its contents
	        this.value = "";
	    });
	});



	$('.contact-form label').each(function() {
		if($(this).text().indexOf('*') >= 0) {
			$(this).addClass('required');
		}
	});


	/*----- Navigation Stuff -----------------------------------------*/


	if(isMobile.phone()) {
		//Mobile Navigation
		addMobileNavigation();

	} else {
		//Add scrollspy events
		addWayPoints();
	}

	//Animation Content Anchor
	addScrollTo();



	// Add Click to external Websites
	//open links with rel="external" in a new window
	$('a[rel=external]').bind('click', function(e) {
		window.open($(this).attr('href'), '_blank');
		e.preventDefault();
	});






}); //document.ready end


//
// $(window).load(function() {
//
// });
//
//
$(window).resize(function(){
	if(projectConfig.intervalId != -1) {
		clearInterval(projectConfig.intervalId);
		projectConfig.intervalId = -1;
	}

	projectConfig.intervalId = setInterval('triggerResize()', 200);
});
